import React from "react";
import "./commitSection.css";
import bannerimg2 from '../../assets/banner/cmmt.jpg';
import { Link } from "react-router-dom";

const SecoendSection = () => {
  return (
    <>
      <div className="container-fluid cmt-section">
      <div className="cmt-heading text-center">
            <h2>Commitment to Excellence</h2>
          </div>
        <div className="row cmt-top">
          <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12 cmt-content">
            <p>
            <h4>Your Quality Conundrums, Our Expert Solutions</h4>
              At AGEP Thailand, our commitment to excellence is unwavering. We
              strive to not only meet but exceed your expectations in every
              service we provide. With a focus on innovation, safety, and
              quality, we are your trusted partner for a wide range of
              industrial solutions.
            </p>
            <p>
              Join us in our mission to safeguard your assets, ensure safety,
              and uphold the highest standards of quality. Together, we build a
              brighter and more secure future for industries around the world.
            </p>
            <Link to="/Portfilio">
              <button className="btn btn-primary-outline">Learn More</button>
            </Link>
          </div>
          <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12 cmmtimg ">
            <img src={bannerimg2} alt="..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecoendSection;
