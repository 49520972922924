import React from "react";
import "./portfiliohome.css";
import { Link } from "react-router-dom";
import portHomeone from "../../assets/Porifilio/projects/06.jpg";
import portHometwo from "../../assets/Porifilio/projects/02.jpg";
import portHomethree from "../../assets/Porifilio/projects/001.jpg";

const PortfolioHome = () => {
  return (
    <>
      <div className="container-fluid port-section">
        <div className="port-heading text-center pt-5">
          <h2>Portfolio</h2>
        </div>
        <div className="row port-top mt-5">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <img src={portHomeone} alt="" srcset="" />
            <h4>Coating Surveys</h4>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <img src={portHometwo} alt="" srcset="" />
            <h4>Product Representation</h4>
          </div> 
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <img src={portHomethree} alt="" />
            <h4>Material Selection</h4>
          </div>
          <div className="d-flex align-content-center justify-content-center mb-5 mt-4">
            <Link to="/Portfilio">
              <button className="btn btn-outline">Explore Projects</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioHome;
