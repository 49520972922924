import React from "react";
import Home from "./pages/Home/Home";
import Portfilio from "./pages/Portfilio/Portfilio";
import Contact from "./pages/Contact/Contact";
import { Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Portfilio" element={<Portfilio />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
    </>
  );
};

export default App;
