import React from "react";
import img1 from "../../assets/thailand_icons/tha1.png";
import img2 from "../../assets/thailand_icons/tai2.png";
import img3 from "../../assets/thailand_icons/thai3.png";
import img4 from "../../assets/thailand_icons/thai4.png";
import img5 from "../../assets/thailand_icons/thai5.png";

import "./mobileicon.css";
const MobileIcon = () => {
  return (
    <>
      <div className="container d-md-none d-sm-block mt-2">
        <div className="row">
          <div className="col-6">
            <div className="card">
              <img src={img1} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-center">Corrosion Control</h5>
                <div id="summary">
                <p
                >We offer customized corrosion solutions for infrastructure,
                <p className="collapse" id="collapsewithlink" >covering assessments, maintenance, compliance, training, risk
                management, and continuous improvement.</p>
                </p>
                <a
                 data-bs-toggle="collapse" href="#collapsewithlink" role="button" aria-expanded="false" aria-controls="collapsewithlink">
                 Read more
                </a>
              </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <img src={img2} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-center">Inspection</h5>
                <div id="summary">
                  <p>
                    Our experts conduct thorough inspections, ensure compliance
                    <p className="collapse" id="collapsetwo">
                      with industry standards, offer guidance on maintenance,
                      comprehensive reporting, and corrective measures.
                    </p>
                  </p>
                  <a
                    data-bs-toggle="collapse"
                    href="#collapsetwo"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapsetwo"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <img src={img3} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-center">Product Management</h5>
                <div id="summary">
                  <p>
                    Involves selecting, procuring, applying, and maintaining
                    <p className="collapse" id="collapseThree">
                      corrosion prevention products to protect assets. It
                      includes proper application, regular maintenance, and
                      compliance with regulations.
                    </p>
                  </p>
                  <a
                    data-bs-toggle="collapse"
                    href="#collapseThree"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Read more
                  </a>
                </div>
                {/* <p className="card-text">
                  Involves selecting, procuring, applying, and maintaining
                  corrosion prevention products to protect assets. It includes
                  proper application, regular maintenance, and compliance with
                  regulations.
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <img src={img4} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-center">Training</h5>
                <div id="summary">
                  <p>
                    {" "}
                    Our industry-leading Quality Control training program
                    <p className="collapse" id="collapseFour">
                      provides hands-on experience, expert guidance, and
                      cutting-edge methodologies. It fortifies industries with
                      top-tier coating quality control standards.
                    </p>
                  </p>
                  <a
                    data-bs-toggle="collapse"
                    href="#collapseFour"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <img src={img5} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title text-center">Audit</h5>
                <div id="summary">
                <p
                >  We ensure compliance and efficiency across operations.
                <p className="collapse" 
                id="collapseFive" > identify areas for improvement, assess risks, and uphold quality
                standards, promoting organizational excellence.</p>
                </p>
                <a data-bs-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">Read more
                </a>
              </div>
           
                {/* <p className="card-text">
                  We ensure compliance and efficiency across operations. They
                  identify areas for improvement, assess risks, and uphold
                  quality standards, promoting organizational excellence.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileIcon;
