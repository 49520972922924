import React from "react";
import postone from "../../assets/thailand_icons/postone.jpg";
import posttwo from "../../assets/thailand_icons/posttwo.jpg";
import postthree from "../../assets/thailand_icons/postthree.jpg";
import "./fbpost.css";

const FbPost = () => {
  return (
    <>
      {/* <div className="container-fluid fb-page pb-5">
        <h2 className='text-light text-center pt-5'>Facebook Post </h2>
        <div className="row d-flex justify-content-center align-content-center d-md-flex d-sm-none">
            <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 fb-img">
                <img src={postone} className='img-fluid' alt='agepthailand'/>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 p-3">
            <img src={postthree} className='img-fluid' alt='agepthailand'/>
            </div>
            <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-4 fb-img">
            <img src={posttwo} className='img-fluid' alt='agepthailand'/>
            </div>
        </div>
      </div> */}
      <div className="container-fluid fb-page justify-content-center align-items-center text-center p-5">
        <div className="row ">
          <h2 className="text-light mb-5">Facebook Post</h2>
          <div className="col-lg-4 col-md-4 col-sm-4 mt-4">
            <img
              src={postone}
              className="img-fluid"
              alt="First post"
              style={{ maxWidth: "100%", maxHeight: "400px", height: "auto" }}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <img
              src={posttwo}
              className="img-fluid"
              alt="Second post"
              style={{ maxWidth: "100%", maxHeight: "600px", height: "auto" }}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 mt-4">
            <img
              src={postthree}
              className="img-fluid"
              alt="Third post"
              style={{ maxWidth: "100%", maxHeight: "400px", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FbPost;
