import img1 from "./gallery/01.jpg";
import img2 from "./gallery/02.jpg";
import img3 from "./gallery/03.jpg";
import img4 from "./gallery/04.jpg";
import img5 from "./gallery/05.jpg";
import img6 from "./gallery/06.jpg";
import img7 from "./gallery/07.jpg";
import img8 from "./gallery/08.jpg";
import img9 from "./gallery/09.jpg";

// 300 =100,100,100
export const Images = [
  {
    id: 1,
    src: img1,
    alt: "Image 1",
    heading:"Material Selection",
  },
  {
    id: 2,
    src: img2,
    alt: "Image 2",
    heading:"Product Representation",
  },
  {
    id: 3,
    src: img3,
    alt: "Image 3",
    heading:"Concrete Coating Inspections",
  },
  
];

export const upImage = [
  {
    id: 4,
    src: img4,
    alt: "Image 4",
    heading:"Coating Inspection",

  },
  {
    id: 5,
    src: img5,
    alt: "Image 6",
    heading:"Failure Analysis",

  },
  {
    id: 6,
    src: img6,
    alt: "Image 7",
    heading:"Coating Surveys",

  },
];

export const Image2 = [
  {
    id: 7,
    src: img7,
    alt: "Image 9",
    heading:"Scaffolding Inspection",

  },
  {
    id: 8,
    src: img8,
    alt: "Image 10",
    heading:"Training Programs",

  },
  {
    id: 9,
    src: img9,
    alt: "Image 11",
    heading:"Specification Writing",

  },
];
